exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-slagthuset-en-js": () => import("./../../../src/pages/about-slagthuset.en.js" /* webpackChunkName: "component---src-pages-about-slagthuset-en-js" */),
  "component---src-pages-events-index-en-js": () => import("./../../../src/pages/events/index.en.js" /* webpackChunkName: "component---src-pages-events-index-en-js" */),
  "component---src-pages-events-index-js": () => import("./../../../src/pages/events/index.js" /* webpackChunkName: "component---src-pages-events-index-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-information-2-about-us-en-js": () => import("./../../../src/pages/information-2/about-us.en.js" /* webpackChunkName: "component---src-pages-information-2-about-us-en-js" */),
  "component---src-pages-information-om-oss-js": () => import("./../../../src/pages/information/om-oss.js" /* webpackChunkName: "component---src-pages-information-om-oss-js" */),
  "component---src-pages-lokaler-js": () => import("./../../../src/pages/lokaler.js" /* webpackChunkName: "component---src-pages-lokaler-js" */),
  "component---src-pages-meetings-event-en-js": () => import("./../../../src/pages/meetings-event.en.js" /* webpackChunkName: "component---src-pages-meetings-event-en-js" */),
  "component---src-pages-moten-event-js": () => import("./../../../src/pages/moten-event.js" /* webpackChunkName: "component---src-pages-moten-event-js" */),
  "component---src-pages-om-slagthuset-js": () => import("./../../../src/pages/om-slagthuset.js" /* webpackChunkName: "component---src-pages-om-slagthuset-js" */),
  "component---src-pages-restaurangen-js": () => import("./../../../src/pages/restaurangen.js" /* webpackChunkName: "component---src-pages-restaurangen-js" */),
  "component---src-pages-restaurant-en-js": () => import("./../../../src/pages/restaurant.en.js" /* webpackChunkName: "component---src-pages-restaurant-en-js" */),
  "component---src-pages-venues-en-js": () => import("./../../../src/pages/venues.en.js" /* webpackChunkName: "component---src-pages-venues-en-js" */),
  "component---src-templates-event-template-js": () => import("./../../../src/templates/event-template.js" /* webpackChunkName: "component---src-templates-event-template-js" */),
  "component---src-templates-lokal-template-js": () => import("./../../../src/templates/lokal-template.js" /* webpackChunkName: "component---src-templates-lokal-template-js" */),
  "component---src-templates-meetings-template-js": () => import("./../../../src/templates/meetings-template.js" /* webpackChunkName: "component---src-templates-meetings-template-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/page-template.js" /* webpackChunkName: "component---src-templates-page-template-js" */)
}

